<template>
  <div ref="wrapper" class="w-full pb-20">
    <section v-for="_group in gridedPosts" class="w-full" :id="`section_${String(_group.label).toLowerCase()}`">
      <p v-if="_group.posts.length > 0" class="p-2 py-8 text-turquoise-500 tracking-[0.3rem] uppercase">{{ _group.label }}</p>

      <div class="grid sm:gap-4 w-full sm:px-0 px- gap-2" :style="`grid-template-columns: repeat(${_group.key == 'completed' ? (columnCount >= 2 ? columnCount : 2) : columnCount}, minmax(0, 1fr));`">
        <div class="w-full space-y-8" v-for="group in _group.posts">
          <NuxtLink v-for="_entry in group" :to="`/app/project/${_entry.id}`" :aria-label="_entry.title">
            <div class="w-full group p-2 rounded-xl flex justify-center items-center flex-col bg-white/5 border-white/5 border mb-4 sm:hover:border-white/20 sm:hover:scale-105 duration-300">
              <div class="flex justify-center items-center w-full flex-col relative rounded-xl overflow-hidden">
                <div v-if="_entry.ath != '-'" class="absolute top-0 z-20 flex justify-center items-center w-full sm:opacity-20 sm:group-hover:opacity-100 opacity-100 duration-300">
                  <div class="ath-clipped">
                    <div class="ath-clipped__content font-bold tracking-widest text-turquoise-1000">
                      <span class="animate-pulse">{{ _entry.ath }}</span>
                    </div>
                  </div>

                  <div class="w-full h-1 bg-turquoise-500 absolute top-0"></div>

                  <div class="absolute text-sm pt-1 text-white translate-y-full">ATH ROI</div>
                </div>

                <LazyNuxtImg loading="lazy" width="200" height="100" quality="20" v-if="_entry.type == 'project'" class="w-full rounded-xl brightness-[40%] border border-white/20 opacity-100 sm:opacity-20 sm:group-hover:opacity-100 duration-300" :src="`${_entry.image}?v=123`" :alt="`${_entry.title} Logo`" />
                <div class="rounded-full w-24 h-24 -mt-12 overflow-hidden p-2 bg-white/20 backdrop-blur-sm">
                  <LazyNuxtImg loading="lazy" width="100" heigh="100" quality="50" class="w-full h-full object-contain rounded-full" :src="`${_entry.logo}?v=123`" :alt="`${_entry.title} Logo`" />
                </div>
              </div>

              <div class="text-center w-full">
                <div class="flex justify-center items-center w-full mb-4 relative overflow-hidden p-4">
                  <div class="w-full text-xl text-zinc-300">{{ _entry.title }}</div>
                  <div class="absolute text-xl text-zinc-300 text-outline scale-[300%] opacity-20">{{ _entry.title }}</div>
                </div>
                <p class="text-sm leading-6 tracking-wide sm:text-zinc-600 text-zinc-400 break-words">{{ _entry.description }}</p>
              </div>

              <div class="w-full px-4 pl-2 pb-4">
                <div class="sm:text-zinc-400 text-zinc-300 mb-4 pl-1">Allocation</div>

                <!-- {{ _entry.tiers[0] }} -->

                <template v-if="_entry.tiers.tier1_min > 0">
                  <!-- <p class="text-woodsmoke-50">Tier One</p> -->

                  <div class="flex justify-start items-center w-full space-x-2 mb-1">
                    <BadgeTierOneSimple />

                    <div class="text-sm sm:text-zinc-500 text-zinc-400">
                      {{ _entry.tiers.tier1_min }} {{ _entry.tiers.currency }}
                      <span v-if="_entry.tiers.tier1_max">&nbsp;-&nbsp;{{ _entry.tiers.tier1_max }} {{ _entry.tiers.currency }}</span>
                    </div>
                  </div>
                </template>

                <template v-if="_entry.tiers.tier2_min > 0">
                  <!-- <p class="text-woodsmoke-50">Tier One</p> -->

                  <div class="flex justify-start items-center w-full space-x-2 mb-1">
                    <BadgeTierTwoSimple />

                    <div class="text-sm sm:text-zinc-500 text-zinc-400">
                      {{ _entry.tiers.tier2_min }} {{ _entry.tiers.currency }}
                      <span v-if="_entry.tiers.tier2_max">&nbsp;-&nbsp;{{ _entry.tiers.tier2_max }} {{ _entry.tiers.currency }}</span>
                    </div>
                  </div>
                </template>

                <template v-if="_entry.tiers.tier3_min > 0">
                  <!-- <p class="text-woodsmoke-50">Tier One</p> -->

                  <div class="flex justify-start items-center w-full space-x-2 mb-1">
                    <BadgeTierThreeSimple />

                    <div class="text-sm sm:text-zinc-500 text-zinc-400">
                      {{ _entry.tiers.tier3_min }} {{ _entry.tiers.currency }}
                      <span v-if="_entry.tiers.tier3_max">&nbsp;-&nbsp;{{ _entry.tiers.tier3_max }} {{ _entry.tiers.currency }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
const props = defineProps(["posts"]);
const { posts } = toRefs(props);

import { useElementSize } from "@vueuse/core";

const wrapper = ref(null);

const { width } = useElementSize(wrapper);
import { useResizeObserver } from "@vueuse/core";
useResizeObserver(wrapper, (entries) => {
  renderView();
});

const columnCount = ref(0);

const gridedPosts = ref([]);

const renderView = () => {
  const _getCol = () => {
    if (width.value < 400) {
      return 1;
    } else {
      let _denom = 1;
      if (window.devicePixelRatio > 1) {
        _denom = Math.floor(window.devicePixelRatio);
      }

      let _minSize = 320;
      if (_denom == 1) _minSize = 320;
      if (_denom == 2) _minSize = 300;

      let _count = Math.floor(width.value / _minSize);

      return _count < 0 ? 1 : _count;
    }
  };

  if (!posts?.value) return;

  columnCount.value = _getCol();

  // console.log(columnCount.value);

  gridedPosts.value = [];

  for (let _group of posts?.value) {
    gridedPosts.value.push({
      ..._group,
      posts: getGrid(_group.posts),
    });
  }
};
let unwatch;
onMounted(() => {
  unwatch = watch(posts, () => {
    renderView();
  });
});

onBeforeRouteLeave(( () => {
  unwatch();
}))

const getGrid = (posts) => {

  if (posts?.length == 0) return [];

  let _columnCount = columnCount.value;

  if (posts[0].type == "project_completed") {
    _columnCount = _columnCount >= 2 ? _columnCount : 2;
  }

  let columns = [];
  for (let i = 0; i < _columnCount; i++) {
    columns.push([]);
  }

  for (let i = 0; i < posts?.length; i++) {
    columns[i % _columnCount].push(posts[i]);
  }

  return columns;
};
</script>

<style scoped>
.text-outline {
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #ffffff;
  color: #00000000;
}

.ath-clipped {
  width: 160px;
  height: 20px;
  border: 0;
  outline: none;
  /* background-color: #000000; */
  cursor: pointer;
  position: relative;
  /* font-size: .85rem; */
  text-transform: uppercase;
  /* color: #000000; */
  clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
}
.ath-clipped__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #27e9af;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
}
</style>

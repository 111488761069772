<template>
  <div class="flex justify-center items-center">
    <div class="mask mask-hexagon w-6 h-6 text-sm flex justify-center items-center bg-gray-500/20 text-gray-500  font-bold">
      <span>2</span>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>